/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import AgencyBackground from '../../component/agencyBackground/AgencyBackground';
import ProgressBar from '../../component/progressBar/ProgressBar';
import BackArrow from '../../component/backArrow/BackArrow';
import { LABELS } from './AdditionalDocumentsConstants';
import { useStyles } from './AdditionalDocumentsStyles';
import Text from '../../component/text/Text';
import Box from '../../component/box/Box';
import { useGetBranchConfig } from '../../services/config/branchConfig';
import ListBox from '../../component/listBox/ListBox';
import {
  CheckOutlined,
  DeleteOutlineOutlined,
  DescriptionOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import { Avatar, Checkbox, Box as MuiBox } from '@mui/material';
import Button from '../../component/button/Button';
import { theme } from '../../theme/Theme';
import { AdditionalDocumentsType } from '../../services/config/branchConfigType';
import {
  useDeleteDocument,
  useGetAllDocs,
  useGetDocsForApplicationId,
  useSubmitAdditionalDocs,
  useUploadDocument,
} from '../../services/additionalVerification/additionalVerificationService';
import { difference, groupBy } from 'lodash';
import localStorage from '../../services/LocalStorage';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from '../../Routes';
import { usePutDraft } from '../../services/draft/draftService';
import { ApplicationFormStep } from '../../services/dashboard/postApplyForm/PostApplyForm.data';
import { UploadedDoc } from '../../services/additionalVerification/additionalVerificationData';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/RootReducers';
import { Dispatch } from 'redux';
import { GroupsActions } from '../../store/actions/GroupsActions';
import DocumentLoader from './DocumentLoader';

interface AdditionalDocumentsProps
  extends RouteComponentProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

const AdditionalDocuments: FC<AdditionalDocumentsProps> = ({
  history,
  properties,
  draftApplication,
  getAllApplications,
}) => {
  const classes = useStyles();
  const { branchConfig, loadingBranchConfig, branchConfigLoaded } = useGetBranchConfig(
    properties![0].agencyCode || (properties![0].agency as string),
  );
  const [bypass, setBypass] = useState<AdditionalDocumentsType[]>([]);
  const [uploadingType, setUploadingType] = useState<AdditionalDocumentsType | null>(null);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [deletingDocId, setDeletingDocId] = useState<string | null>(null);
  const [deletedDocIds, setDeletedDocIds] = useState<string[]>([]);
  const [uploadedInCurrentSession, setUploadedInCurrentSession] = useState<UploadedDoc[]>([]);
  const [redirectingToNextStep, setRedirectingToNextStep] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const email = localStorage.getItem('email')!;
  const draftId = localStorage.getItem('draftId')!;
  const applicationRefId = properties![0].applicationRefId;

  // api calls

  const { uploadFile, uploadingFile } = useUploadDocument();
  const { getDocsForDraft: getDocsForApplication, documentsForDraft } = useGetDocsForApplicationId(
    (draftId || applicationRefId)!,
    draftId ? 'draft' : 'update',
  );
  const { uploadedDocs: allUploadedDocs, refetchDocs: getAllDocs } = useGetAllDocs({ email });
  const { deleteDoc } = useDeleteDocument();
  const { submitDocs, submittingDocs } = useSubmitAdditionalDocs();
  const { updateDraft } = usePutDraft();

  const requiredDocs = useMemo(
    () => branchConfig?.customProfileConfig.additionalDocuments,
    [branchConfig],
  );

  useEffect(() => {
    getAllApplications();
  }, []);

  useEffect(() => {
    if (branchConfigLoaded && (!draftId || draftApplication)) {
      if (
        !draftId ||
        (draftId && (draftApplication as any).applicationRequest.currentStep === 'Submit')
      ) {
        getDocsForApplication()
          .then((res) => {
            const bypassDocs = difference(
              requiredDocs!.map((ad) => ad.type),
              Object.keys(groupBy(res.data, (el) => el.type)),
            ) as AdditionalDocumentsType[];
            setLoading(false); // Set loading state first
            setBypass(bypassDocs); // Then update bypass state
          })
          .catch(() => {});
      } else {
        getAllDocs()
          .then(() => {
            setLoading(false);
          })
          .catch(() => {});
      }
    }
  }, [branchConfigLoaded, draftApplication]);

  const categorisedUploadedDocs = useMemo(() => {
    const requiredDocs = [
      ...(documentsForDraft || allUploadedDocs || []),
      ...uploadedInCurrentSession,
    ].filter((d) => !deletedDocIds.includes(d.id));

    if (requiredDocs.length > 0) {
      return groupBy(requiredDocs, (el) => el.type);
    }
    return undefined;
  }, [allUploadedDocs, documentsForDraft, uploadedInCurrentSession, deletedDocIds]);

  const uploadedTypes = categorisedUploadedDocs ? Object.keys(categorisedUploadedDocs) : [];
  const disabled =
    !requiredDocs ||
    requiredDocs.some(
      ({ type, count }) =>
        !bypass.includes(type) &&
        (!categorisedUploadedDocs || (categorisedUploadedDocs?.[type] || []).length < (count || 1)),
    ) ||
    submittingDocs;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      uploadFile(
        { email, type: uploadingType!, files: Array.from(event.target.files) },
        {
          onSuccess: (data) => {
            setUploadedInCurrentSession((uics) => [...uics, ...data]);
            inputRef.current!.value = '';
          },
          onError: (err) => {
            setUploadError(err);
          },
          onSettled: () => {
            setUploadingType(null);
          },
        },
      );
    }
  };

  const uploadedDocs = [
    ...(documentsForDraft || allUploadedDocs || []),
    ...uploadedInCurrentSession,
  ];

  const showLoader = loading || loadingBranchConfig;

  return (
    <>
      <AgencyBackground />
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <ProgressBar
            steps={['Properties', 'Application', 'Documents', 'Submit']}
            activeStep={2}
          />
        </div>
        <div className={classes.pageContainer}>
          <BackArrow smallGap />
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.pageContent}>
          <div className={classes.primaryContainer}>
            <div className={classes.contentTitleContainer}>
              <Text textVariant="boxTitle">{LABELS.heading}</Text>
            </div>
          </div>
        </div>

        {showLoader ? (
          <ListBox gap="1em">
            <DocumentLoader />
            <DocumentLoader />
          </ListBox>
        ) : (
          <>
            <ListBox>
              {requiredDocs!.map(({ title, subtitle, bypassLabel, type, count }) => {
                const complete =
                  bypass.includes(type) ||
                  (categorisedUploadedDocs &&
                    categorisedUploadedDocs?.[type]?.filter((d) => !deletedDocIds.includes(d.id))
                      .length >= (count || 1));

                const uploadingToThisCategory = uploadingFile && uploadingType === type;
                return (
                  <Box lightBorder key={title}>
                    <ListBox direction="row">
                      <Avatar
                        className={`${classes.statusIconAvatar} ${
                          complete ? 'complete' : 'incomplete'
                        }`}
                      >
                        {complete ? (
                          <CheckOutlined className={classes.completeIcon} />
                        ) : (
                          <InfoOutlined className={classes.incompleteIcon} />
                        )}
                      </Avatar>
                      <ListBox gap="0.8em">
                        <Text parentStyles={classes.sectionHeading} textVariant="contentTitle">
                          {title} {count ? `(${count} Required)` : ''}
                        </Text>
                        <Text textVariant="info">{subtitle}</Text>

                        <MuiBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <ListBox gap="0.2em" style={{ alignItems: 'center' }} direction="row">
                            <Checkbox
                              onChange={(_e, checked) => {
                                if (checked) {
                                  setBypass((bp) => [...bp, type]);
                                } else {
                                  setBypass((bp) => bp.filter((b) => b !== type));
                                }
                              }}
                              checked={bypass.includes(type)}
                              style={{ color: theme.colors.secondary }}
                              disabled={uploadedTypes.includes(type)}
                            />
                            <Text textVariant="info">{bypassLabel}</Text>
                          </ListBox>
                          <Button
                            parentStyles={classes.uploadButton}
                            onPress={() => {
                              if (inputRef.current) {
                                inputRef.current.click();
                              }
                              setUploadingType(type);
                              setUploadError(null);
                            }}
                            disabled={uploadingToThisCategory || bypass.includes(type)}
                          >
                            {uploadingToThisCategory ? LABELS.uploading : LABELS.upload}
                          </Button>
                        </MuiBox>

                        {uploadError && uploadingType === type && (
                          <MuiBox mt={2}>{uploadError}</MuiBox>
                        )}

                        {/* Uploaded files list */}
                        {categorisedUploadedDocs?.[type] ? (
                          <ListBox>
                            {categorisedUploadedDocs?.[type].map((f) => {
                              if (deletedDocIds.includes(f.id)) return null;
                              const beingDeleted = deletingDocId === f.id;
                              return (
                                <MuiBox
                                  className={`${classes.fileBox} ${beingDeleted ? 'deleting' : ''}`}
                                >
                                  <ListBox direction="row">
                                    <DescriptionOutlined /> {f.fileData.fileName}
                                  </ListBox>
                                  <DeleteOutlineOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      if (!beingDeleted) {
                                        setDeletingDocId(f.id);
                                        deleteDoc(
                                          { documentId: f.id },
                                          {
                                            onSuccess: () => {
                                              setDeletedDocIds((ddi) => [...ddi, f.id]);
                                              setDeletingDocId(null);
                                            },
                                            onError: () => {
                                              setDeletingDocId(null);
                                            },
                                          },
                                        );
                                      }
                                    }}
                                  />
                                </MuiBox>
                              );
                            })}
                          </ListBox>
                        ) : null}
                      </ListBox>
                    </ListBox>
                  </Box>
                );
              })}
            </ListBox>
            <MuiBox my={2} display="flex" justifyContent="flex-end">
              <Button
                disabled={disabled || redirectingToNextStep}
                onPress={() => {
                  submitDocs(
                    {
                      documentIds:
                        uploadedDocs
                          ?.map((ud) => ud.id)
                          .filter((i) => !deletedDocIds.includes(i)) || [],
                      email,
                      draftApplicationId: parseInt(localStorage.getItem('draftId')!),
                      submittedApplicationRefId: applicationRefId || undefined,
                    },
                    {
                      onSuccess: () => {
                        if (draftId) {
                          setRedirectingToNextStep(true);
                          updateDraft(
                            {
                              ...draftApplication,
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                              applicationRequest: {
                                ...(draftApplication as any)?.applicationRequest,
                                currentStep: ApplicationFormStep.SUBMIT,
                              },
                            } as any,
                            {
                              onSuccess: () => {
                                getAllApplications();
                                history.push(routes.submitProperties.new);
                              },
                            },
                          );
                        } else {
                          getAllApplications();
                          history.push(routes.submitProperties.new);
                        }
                      },
                    },
                  );
                }}
              >
                {submittingDocs
                  ? LABELS.submitting
                  : redirectingToNextStep
                  ? LABELS.redirecting
                  : LABELS.submit}
              </Button>
            </MuiBox>
            {/* Hidden file input */}
            <input
              type="file"
              ref={inputRef}
              style={{ display: 'none' }}
              accept="image/*,.pdf"
              onChange={handleFileChange}
            />{' '}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  properties: state.landingScreen.properties,
  draftApplication: localStorage.getItem('draftId')
    ? state.groups.groupApplications?.draftApplications.agencyWiseApplications
        .find(
          (o) =>
            o.agency ===
            (state.landingScreen.properties![0].agency ||
              state.landingScreen.properties![0].agencyCode),
        )
        ?.application.find((app) => +app.draftId! === +localStorage.getItem('draftId')!)
    : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllApplications: () => {
    dispatch(GroupsActions.getAllApplicationsRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdditionalDocuments));
