/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import { Typography, Input, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useStyles } from './DeclarationFormStyles';
import { LABELS } from './DeclarationFormConstants';
import { ApplicantProfile, DigitalSignature } from '../../../../store/state/MyProfileFormState';

import { CustomTermsHeaderResponse } from '../../../../store/actions/CustomTermsHeaderActions';
import { ApplicationState } from '../../../../store/RootReducers';
import ScrollBox from './components/ScrollBox';
import { PropertyDetailsResponse } from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { GroupsActions, GroupsActionTypes } from '../../../../store/actions/GroupsActions';
import {
  PostGroupApplicationRequest,
  InvitationRequest,
  SyncApplicationRequest,
  UpdateGroupApplicationRequest,
} from '../../../../services/groups/Groups';
import { getUpdatePropertyData } from '../../../../helper/PostHelper';
import { UpdatingType, UPDATING_TYPE } from '../../../../services/dashboard/updating/Updating';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { ErrorActions } from '../../../../store/actions/ErrorActions';

interface DeclarationFormProps {
  profileFormDetails: ApplicantProfile;
  headerDetails: CustomTermsHeaderResponse | null | undefined;
  isUpdating: UpdatingType;
  isAcceptingInvitation: boolean;
  applicationState: ApplicationState;
  selectedGroupRefId: string | null;
  selectedProperties: PropertyDetailsResponse[];
  error: Error | string;
  setShowDeclaration: (show: boolean) => void;
  acceptInvitation: (data: InvitationRequest) => void;
  syncApplications: (data: SyncApplicationRequest) => void;
  postGroupApplication: (data: PostGroupApplicationRequest) => void;
  updateGroupApplication: (data: UpdateGroupApplicationRequest) => void;
  clearErrors: () => void;
}

const DeclarationForm: FC<DeclarationFormProps> = ({
  profileFormDetails,
  headerDetails,
  isUpdating,
  isAcceptingInvitation,
  applicationState,
  selectedGroupRefId,
  selectedProperties,
  error,
  setShowDeclaration,
  acceptInvitation,
  syncApplications,
  postGroupApplication,
  updateGroupApplication,
  clearErrors,
}) => {
  const classes = useStyles();
  const [signature, setSignature] = useState<DigitalSignature>(
    profileFormDetails.declarationDetails!.digitalSignature || '',
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, []);

  const handleSubmit = () => {
    if (signature && !isSubmitting) {
      setIsSubmitting(true);
      if (isUpdating === UPDATING_TYPE.UPDATING) {
        updateGroupApplication({
          applicationRef: selectedProperties[0].applicationRefId!,
          propertyDetails: getUpdatePropertyData(applicationState),
        });
        // test3
      } else if (isUpdating === UPDATING_TYPE.SYNCING) {
        const toUpdate: string[] = [];
        selectedProperties.map((property) => {
          toUpdate.push(property.applicationRefId!);
        });
        syncApplications({ applicationRefs: toUpdate });
      } else if (isAcceptingInvitation) {
        const toAccept: string[] = [];
        selectedProperties.map((property) => {
          toAccept.push(property.applicationRefId!);
        });
        acceptInvitation({ applicationRefs: toAccept, groupRefId: selectedGroupRefId as string });
      } else {
        postGroupApplication({ groupRefId: selectedGroupRefId as string, applicationState });
      }
    }
  };

  return (
    <div className={classes.formStyle}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {isUpdating !== false
            ? isUpdating === UPDATING_TYPE.UPDATING
              ? LABELS.UPDATE
              : LABELS.SYNC
            : LABELS.DECLARATION}
        </div>
        <CloseIcon className={classes.closeIcon} onClick={() => setShowDeclaration(false)} />
      </div>
      {isUpdating !== false && (
        <div className={classes.isUpdatingText}>
          {LABELS.UPDATING_TEXT(selectedProperties.length === 1)}
        </div>
      )}
      <ScrollBox headerDetails={headerDetails} selectedProperties={selectedProperties} />

      <Typography className={classes.agreeOnSignStyle}>{LABELS.AGREE_ON_SIGN}</Typography>
      <Typography className={classes.confirmMessageStyle}>{LABELS.CONFIRMATION_MESSGE}</Typography>

      <div className={classes.signingContainer}>
        <FormControl className={classes.inputContainerStyle}>
          <Typography className={classes.signingLabel} variant="subtitle1">
            {LABELS.SIGN_HERE}
          </Typography>
          <Input
            className={classes.inputStyle}
            name="digitalSignature"
            placeholder="Type here..."
            type="text"
            disableUnderline
            onChange={(event) => setSignature(event.target.value)}
            value={signature}
          />
        </FormControl>
      </div>

      <div className={classes.buttonContainer}>
        <div className={classes.secondaryButton} onClick={() => setShowDeclaration(false)}>
          Back
        </div>
        {!error ? (
          <div
            className={`${classes.primaryButton} ${
              !signature || isSubmitting ? classes.disabled : undefined
            }`}
            onClick={() => handleSubmit()}
          >
            {isSubmitting ? 'Submitting...' : 'Accept'}
          </div>
        ) : (
          <div className={classes.errorButton}>{console.log('error', error)}Failed</div>
        )}
      </div>
    </div>
  );
};

const error = errorMessageSelector([
  GroupsActionTypes.ACCEPT_INVITATION,
  GroupsActionTypes.SYNC_APPLICATIONS,
  GroupsActionTypes.POST_GROUP_APPLICATION,
  GroupsActionTypes.UPDATE_GROUP_APPLICATION,
]);

const mapStateToProps = (state: ApplicationState) => ({
  profileFormDetails: state.myProfileForm,
  applicationState: state,
  headerDetails: state.termsHeader.termsHeaderDetails,
  isUpdating: state.dashboard.isUpdating,
  isAcceptingInvitation: state.dashboard.isAcceptingInvitation,
  selectedGroupRefId: state.groups.selectedGroupRefId,
  selectedProperties: state.landingScreen.properties!,
  error: error(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  acceptInvitation: (data: InvitationRequest) => {
    dispatch(GroupsActions.acceptInvitationRequest(data));
  },
  syncApplications: (data: SyncApplicationRequest) => {
    dispatch(GroupsActions.syncApplicationsRequest(data));
  },
  postGroupApplication: (data: PostGroupApplicationRequest) => {
    dispatch(GroupsActions.postGroupApplicationRequest(data));
  },
  updateGroupApplication: (data: UpdateGroupApplicationRequest) => {
    dispatch(GroupsActions.updateGroupApplicationRequest(data));
  },
  clearErrors: () => {
    dispatch(ErrorActions.clearAllErrors());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationForm);
