export enum ErroActionTypes {
  CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS',
}

export interface ErrorAction {
  type: ErroActionTypes;
  data: undefined;
}

export class ErrorActions {
  public static clearAllErrors() {
    return { type: ErroActionTypes.CLEAR_ALL_ERRORS };
  }
}
