import React, { FC } from 'react';
import ApplyBox from '../../../component/box/Box';
import Shimmer from '../../../component/loader/Shimmer';
import { Box } from '@mui/material';

interface ProfileLoaderProps {}

const ProfileLoader: FC<ProfileLoaderProps> = () => {
  return (
    <ApplyBox lightBorder>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap="1em">
          <Shimmer style={{ borderRadius: '50%' }} width="60px" height="60px" />

          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Shimmer width="100px" height="20px" />
            <Shimmer width="50px" height="20px" />
          </Box>
        </Box>

        <Shimmer style={{ borderRadius: '50%' }} width="60px" height="60px" />
      </Box>
    </ApplyBox>
  );
};

export default ProfileLoader;
