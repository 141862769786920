import ApiService from '../ApiService';
import { useFetchQuery, useMutationQuery } from '../reactQuery/reactQuery';
import { ServiceType } from '../ServiceType.data';
import {
  DeleteAdditionalDocumentPayload,
  GetAdditionalDocsPayload,
  SubmitAdditionalDocsPayload,
  UploadedDoc,
  VerificationFileUploadPayload,
} from './additionalVerificationData';

const service = new ApiService(ServiceType.AdditionalVerification);

export const useUploadDocument = () => {
  const { mutate: uploadFile, isLoading: uploadingFile } = useMutationQuery<
    UploadedDoc[],
    VerificationFileUploadPayload
  >({
    key: ['upload-verification'],
    func: (data: VerificationFileUploadPayload) =>
      service.postMultipart(
        { route: ['documents', 'upload'], query: { email: data.email, type: data.type } },
        data.files.map((f) => ({ name: 'files', file: f })),
      ),
  });

  return {
    uploadFile,
    uploadingFile,
  };
};

export const useDeleteDocument = () => {
  const { mutate: deleteDoc, isLoading: deletingDoc } = useMutationQuery({
    key: ['delete-additional-doc'],
    func: (data: DeleteAdditionalDocumentPayload) =>
      service.delete({ route: ['documents'], query: { ids: data.documentId } }),
  });

  return {
    deleteDoc,
    deletingDoc,
  };
};

export const useSubmitAdditionalDocs = () => {
  const { mutate: submitDocs, isLoading: submittingDocs } = useMutationQuery({
    key: ['submit-additional-docs'],
    func: (data: SubmitAdditionalDocsPayload) => service.post({ route: ['submit'] }, data),
  });

  return {
    submitDocs,
    submittingDocs,
  };
};

export const useGetAllDocs = (payload: GetAdditionalDocsPayload) => {
  const {
    data: uploadedDocs,
    isFetching: fetchingUploadedDocs,
    refetch: refetchDocs,
  } = useFetchQuery<UploadedDoc[], {}>(
    ['get-additional-docs', payload],
    () => {
      return service.get({ route: ['documents', 'all'], query: { email: payload.email } });
    },
    { enabled: false },
  );

  return { uploadedDocs, fetchingUploadedDocs, refetchDocs };
};

export const useGetDocsForApplicationId = (appId: number | string, type: 'draft' | 'update') => {
  const {
    data,
    isFetching,
    refetch: getDocsForDraft,
  } = useFetchQuery<UploadedDoc[], {}>(
    ['getDocsForApplicationId', { appId }],
    () =>
      service.get({
        route: ['documents', 'submitted'],
        query:
          type === 'draft'
            ? { draftApplicationId: appId, email: localStorage.getItem('email')! }
            : { submittedApplicationRefId: appId, email: localStorage.getItem('email')! },
      }),
    { enabled: false },
  );

  return { documentsForDraft: data, loadingDocumentsForDraft: isFetching, getDocsForDraft };
};
