import { Box } from '@mui/material';
import React, { FC } from 'react';
import Shimmer from '../../component/loader/Shimmer';
import ApplyBox from '../../component/box/Box';
import ListBox from '../../component/listBox/ListBox';

interface DocumentLoaderProps {}

const DocumentLoader: FC<DocumentLoaderProps> = () => {
  return (
    <ApplyBox lightBorder>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box width="100%" display="flex" gap="1em">
          <Shimmer style={{ borderRadius: '50%' }} width="60px" height="60px" />

          <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
            <Shimmer width="100px" height="30px" />
            <Shimmer width="50px" height="20px" />
            <Box my={2} width="100%">
              <ListBox gap="1em">
                <Shimmer width="100%" height="20px" />
                <Shimmer width="100%" height="20px" />
                <Shimmer width="100%" height="20px" />
              </ListBox>
            </Box>
          </Box>
        </Box>
      </Box>
    </ApplyBox>
  );
};

export default DocumentLoader;
