import * as Yup from 'yup';
import { NAME_PATTERN, NUMBER_PATTERN, EMAIL_PATTERN } from '../../../../constants/AppConstants';
import { LivingArrangement, ManualAddressData } from '../../../../store/state/MyProfileFormState';
import { store } from '../../../../store/Store';

export const LABELS = {
  ADDRESS: 'Address',
  CURRENT_ADDRESS: 'Current Address',
  ADDRESS_HISTORY: 'Address History',
  PREVIOUS_ADDRESS: 'Previous Address',
  LIVING_ARRANGEMENT: 'Living arrangement',
  RENTED_THROUGH_AN_AGENT: 'Rented through an agent',
  RENTING_THROUGH_AGENT: 'Renting through an agent',
  RENTING_THROUGH_LANDLORD: 'Renting through a private landlord',
  PROPERTY_MANAGER_NAME: 'Property manager full name',
  CONTACT_NUMBER: 'Contact number',
  MONTHLY_RENT: 'Monthly rent',
  HOW_LONG_HAVE_YOU_LIVED_HERE: 'How long have you lived here?',
  HOW_LONG_DID_YOU_LIVE_THERE: 'How long did you live there?',
  REASON_FOR_LEAVING: 'Reason for leaving',
  DIFFERENT_ADDRESS: 'Is your postal address different to your current address?',
  RECOMMENDATION:
    'We recommend you have at least 1 year rental history to boost your chance to success!',
  POSTAL_ADDRESS: 'Postal address',
  AGENCY_NAME: 'Agency name',
  ADD_PREVIOUS_ADDRESS: '+ Add previous address',
  BUTTON_TEXT: 'Save and next',
  LATER: "I'LL DO IT LATER",
  YEAR_RANGE: 10,
  YEAR: 'Year(s)',
  MONTH: 'Month(s)',
  REMOVE: 'Remove',
  EMAIL: 'Email',
  REFERENCE_ATTACHED: 'Reference attached',
  NO_RESPONSE_GIVEN: 'No response given',
  REFERENCE_ATTACHED_MESSAGE: 'Reference is attached to this rental record',
  NO_RESPONSE_GIVEN_MESSAGE: `Your reference did not respond. You might want to contact them 
    directly to ensure they are receiving the request.`,
  WARNING_TEXT: `Your Property Manager or former rental provider will be contacted as a reference to confirm the details you have provided. 
    The reference will be linked to your application profile and used when submitting this and future applications. You will receive 
    confirmation when it is linked. Please note, only agencies you submit applications to will have access to the responses provided 
    by your referee.`,
  RATES_NOTICE_TEXT: 'Please add rates notice',
  TENANCY_LEDGER_TEXT:
    'Please add tenancy ledger or recent rent receipts or recent inspection report',
  ADDITIONAL_DOCUMENTATION_LABEL: 'Attach additional documents (PDF, JPG, PNG) 10MB ',
};

export enum SubmissionStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
}

export const ERRORS = {
  required: 'Required',
  requiredEmail: 'Please provide an email address',
  invalidNameFormat: 'Invalid name format',
  invalidCountryNumber: 'Invalid phone number',
  minMonthlyRent: 'Cannot be less than 1',
  invalidEmailFormat: 'Invalid email format',
  maxStringLength: 'Can be at most 255 characters',
  requiredManagerName: 'Please provide property manager name',
  requiredPhoneNumber: 'Please provide the phone number',
  requiredAddress: 'Please provide the address',
  requiredLivingArrangement: 'Please select the living arrangement',
  requiredMonthlyRent: 'Please provide the monthly rent',
  selfReferenced: 'Cannot add yourself as a reference',
  otherReferenced: 'You cannot add someone as a reference who you are also applying with',
  manually: 'Missing required fields. Please fill in manually',
};

const { tenantDetails } = store.getState().landingScreen;

const isValidAddressComponent = ({
  postcode,
  state,
  streetName,
  streetNumber,
  suburb,
}: ManualAddressData) => {
  if (!postcode || !state || !streetName || !streetNumber || !suburb) return false;
  return true;
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  addresses: Yup.array().of(
    Yup.object().shape({
      postalAddressDifferentFromCurrentAddress: Yup.boolean(),
      livingArrangement: Yup.string()
        .max(255, ERRORS.maxStringLength)
        // .required(ERRORS.requiredLivingArrangement)
        .nullable(),
      agencyName: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
      address: Yup.string().max(255, ERRORS.maxStringLength).nullable(),
      // .required(ERRORS.requiredAddress),
      propertyManagerName: Yup.string()
        .when('livingArrangement', {
          is: (livingArrangement) =>
            (livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
              livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD) &&
            livingArrangement !== '',
          then: Yup.string()
            .max(255, ERRORS.maxStringLength)
            // .required(ERRORS.requiredManagerName)
            .nullable(),
        })
        .nullable()
        .max(255, ERRORS.maxStringLength)
        .matches(NAME_PATTERN, ERRORS.invalidNameFormat),
      contactNumber: Yup.string().when('livingArrangement', {
        is: (livingArrangement) =>
          (livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
            livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD) &&
          livingArrangement !== '',
        then: Yup.string().nullable(),
      }),
      monthlyRent: Yup.string()
        .matches(NUMBER_PATTERN, ERRORS.minMonthlyRent)
        .nullable()
        .when('livingArrangement', {
          is: (livingArrangement) =>
            (livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
              livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD) &&
            livingArrangement !== '',
          then: Yup.string().nullable(),
        }),
      postalAddress: Yup.string().nullable().when('postalAddressDifferentFromCurrentAddress', {
        is: true,
        then: Yup.string().nullable(),
      }),
      durationOfStayYear: Yup.mixed().nullable(),
      durationOfStayMonth: Yup.mixed().nullable(),
      email: Yup.string()
        .nullable()
        .when('livingArrangement', {
          is: (livingArrangement) =>
            livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
            livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD,
          then: Yup.string()
            .max(255, ERRORS.maxStringLength)
            .test('value', ERRORS.selfReferenced, (value) => {
              if (tenantDetails) {
                return value !== tenantDetails.email;
              }
              return true;
            })
            .test('value', ERRORS.otherReferenced, (value) => {
              let result = true;
              if (tenantDetails) {
                tenantDetails.otherApplicants.map((other) => {
                  result = value !== other.email;
                });
              }
              return result;
            })
            .nullable(),
        })
        .when('livingArrangement', {
          is: (livingArrangement) =>
            (livingArrangement === LivingArrangement.RENTING_VIA_AGENT ||
              livingArrangement === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD) &&
            livingArrangement !== '',
          then: Yup.string()
            .max(255, ERRORS.maxStringLength)
            // .required(ERRORS.requiredEmail)
            .nullable()
            .test('value', ERRORS.selfReferenced, (value) => {
              if (tenantDetails) {
                return value !== tenantDetails.email;
              }
              return true;
            })
            .test('value', ERRORS.otherReferenced, (value) => {
              let result = true;
              if (tenantDetails) {
                tenantDetails.otherApplicants.map((other) => {
                  result = value !== other.email;
                });
              }
              return result;
            })
            .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
        }),
    }),
  ),
});
