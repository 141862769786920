import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LogRocket from 'logrocket';
import moment from 'moment';
import { Dispatch } from 'redux';
import Header from './component/header/Header';
import SortedFooter from './component/sortedFooter/SortedFooter';
import { routes } from './Routes';
import ErrorPage from './component/errorPage/ErrorPage';
import MyProfileFormsContainer from './container/profile/Profile';
import LoginContainer from './container/login/Login';
import ResetPasswordContainer from './container/resetPassword/ResetPassword';
import ResetPasswordConfirmationComponent from './component/resetPasswordConfirmation/ResetPasswordConfirmation';
import { ApplicationState } from './store/RootReducers';
import ApplicationSent from './container/applicationSent/ApplicationSent';
import RegistrationConfirmationComponent from './container/registerConfirmation/RegisterConfirmation';
import Dashboard from './container/dashboard/Dashboard';
import QuestionnaireScreen from './component/referenceToolScreens/questionnaire/QuestionnaireScreen';
import WelcomeRefereeScreen from './component/referenceToolScreens/welcomeReferee/WelcomeRefereeScreen';
import ThankyouScreen from './component/referenceToolScreens/thankyouScreen/ThankyouScreen';
import ErrorScreen from './component/referenceToolScreens/errorScreen/ErrorScreen';
import ViewPropertyScreen from './container/viewProperty/ViewProperty';
import SelectPropertiesScreen from './container/selectProperties/SelectProperties';
import SubmitPropertiesScreen from './container/submitProperties/SubmitProperties';
import ApplicationScreen from './container/application/Application';
import RemoteSigning from './container/remoteSigning/RemoteSigning';
import { AppSettings } from './AppSettings';
import { apiSanitizer } from './helper/LogRocketHelper';
import localStorage from './services/LocalStorage';
import usePageVisibility from './helper/pageVisibilityHelper';
import MaintenanceHeader from './component/maintenanceHeader/MaintenanceHeader';
import { maintenancePagesURLIdentifier } from './constants/AppConstants';
import MaintenanceForm from './container/tenantMR/MaintenanceForm';
import { SuccessActions } from './store/actions/SuccessActions';
import { LoginActions } from './store/actions/LoginActions';
import Disclosure from './container/disclosure/Disclosure';
import { getPageTitle } from './helper/AppHelper';
import { useStyles } from './AppStyles';
import Menu from './component/menu/Menu';
import Groups from './container/groups/Groups';
import EditGroup from './container/editGroup/EditGroup';
import Applications from './container/applications/Applications';
import AdvancedSearch from './container/advancedSearch/AdvancedSearch';
import useInterval from './hooks/UseInterval';
import { versionSync } from './helper/VersionSync';
import ShortApplication from './container/shortApplication/ShortApplication';
import { getQueryParams } from './container/remoteSigning/RemoteSigningUtils';
import ResidentRequest from './container/residentRequest/ResidentRequest';
import AdditionalDocuments from './container/additionalDocuments/AdditionalDocuments';

LogRocket.init(AppSettings.logRocket.group, {
  network: {
    requestSanitizer: (request) => {
      const shouldExclude = apiSanitizer(request.url);
      return shouldExclude ? null : request;
    },
  },
});

const referenceToolScreens = ['reference-tool', 'questionnaire', 'thankyou-screen', 'error-screen'];

interface AppProps extends RouteComponentProps {
  flushData: () => void;
  refreshMasterProfile: () => void;
}

const App: FC<AppProps> = ({ location, history, flushData, refreshMasterProfile }) => {
  const [reloadPopUpOpen, setReloadPopUpOpen] = useState<boolean>(false);
  const isVisible = usePageVisibility();
  const classes = useStyles();
  const firstnameURL = getQueryParams(location, 'firstname');
  const lastnameURL = getQueryParams(location, 'lastname');
  const emailURL = getQueryParams(location, 'email');
  const userIdURL = getQueryParams(location, 'userId');
  const accessTokenURL = getQueryParams(location, 'authToken');
  const refreshTokenURL = getQueryParams(location, 'refreshToken');

  let userEmail = localStorage.getItem('email');
  let userId = localStorage.getItem('userId');

  useEffect(() => {
    if (firstnameURL && lastnameURL && emailURL && userIdURL && accessTokenURL && refreshTokenURL) {
      localStorage.setItem('authToken', accessTokenURL);
      localStorage.setItem('refreshToken', refreshTokenURL);
      localStorage.setItem('firstname', firstnameURL);
      localStorage.setItem('lastname', lastnameURL);
      localStorage.setItem('email', emailURL);
      localStorage.setItem('userId', userIdURL);
      userId = userIdURL;
      userEmail = emailURL;
    }
  }, [firstnameURL, lastnameURL, emailURL, userIdURL, accessTokenURL, refreshTokenURL]);

  // Version check logic
  useInterval(
    () => {
      if (!reloadPopUpOpen)
        versionSync(
          false,
          signOut,
          () => {
            setReloadPopUpOpen(true);
          },
          () => {
            setReloadPopUpOpen(false);
          },
        );
    },
    // passing null if confirmation modal is open otherwise calls stack up
    // i.e. on clicking on cancel/ok other pop-up queued for the time will immediately show up
    // implementation of useInterval clears interval on passing null;
    // setting a interval of 10 minutes which means we check do a version check every 10 minutes
    reloadPopUpOpen ? null : 600000,
  );

  // syncing version on mount
  useEffect(() => {
    versionSync(true);
  }, []);

  const handleUnmount = (event: Event) => {
    event.preventDefault();
    event.returnValue = false;
  };

  if (userEmail && userId) {
    LogRocket.identify(userEmail, {
      email: userEmail,
      userId,
    });
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnmount);

    const bpointScript = document.createElement('script');
    const environment = AppSettings.server.baseUrl.includes(
      'apply-service.prodau.sortedservices.com',
    )
      ? 'https://www.bpoint.com.au/webapi/CBA/api.js?v=2'
      : 'https://bpoint.uat.linkly.com.au/webapi/CBA/api.js?v=2';
    bpointScript.type = 'text/javascript';
    bpointScript.src = environment as string;
    document.head.appendChild(bpointScript);
    AppSettings.initApp();
  }, []);

  useEffect(() => {
    if (location.pathname === routes.success.new || routes.error.new) {
      window.removeEventListener('beforeunload', handleUnmount);
    }
  }, [location]);

  const signOut = () => {
    flushData();
    localStorage.setItem('userId', '');
    history.push(routes.login.view);
  };

  const isReferenceScreen = () => {
    let result = false;
    const url = window.location.href;
    referenceToolScreens.forEach((element) => {
      if (url.includes(element)) {
        result = true;
      }
    });
    return result;
  };

  useEffect(() => {
    if (!isVisible) {
      localStorage.setItem('timeAway', moment().format());
    } else if (isVisible) {
      const timeAway = moment(localStorage.getItem('timeAway') as string);
      const minutesAway = moment().diff(timeAway, 'minutes');
      if (userId && !isReferenceScreen() && minutesAway >= 30) {
        localStorage.setItem('timeAway', moment().format());
        signOut();
      } else if (userId && !isReferenceScreen() && minutesAway >= 15) {
        localStorage.setItem('timeAway', moment().format());
        refreshMasterProfile();
      }
    }
  }, [isVisible]);

  const tenantMR = location.pathname.includes(maintenancePagesURLIdentifier);

  return (
    <>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>
      <Route
        path="*"
        render={() => (
          <>
            {!isReferenceScreen() && <Header />}
            <div className={classes.layout}>
              <Menu />
              <div className={classes.content}>
                {!userId ? (
                  <Switch>
                    <Route path={routes.login.view} exact component={LoginContainer} />
                    <Route
                      path={routes.registrationConfirmation.new}
                      exact
                      component={RegistrationConfirmationComponent}
                    />
                    <Route
                      path={routes.welcomeReferee.view}
                      exact
                      component={WelcomeRefereeScreen}
                    />
                    <Route path={routes.questionnaire.view} exact component={QuestionnaireScreen} />
                    <Route path={routes.thankyouScreen.view} exact component={ThankyouScreen} />
                    <Route path={routes.referenceToolError.view} exact component={ErrorScreen} />
                    <Route
                      path={routes.resetPasswordConfirmation.new}
                      exact
                      component={ResetPasswordConfirmationComponent}
                    />
                    <Route
                      path={routes.resetPassword.view}
                      exact
                      component={ResetPasswordContainer}
                    />
                    <Route
                      path={routes.tenantMaintenanceRequestForm.view}
                      exact
                      component={MaintenanceForm}
                    />
                    <Route
                      path={routes.viewProperty.view(':refId')}
                      exact
                      component={ViewPropertyScreen}
                    />
                    <Route
                      path={routes.remoteSigning.view(':token')}
                      exact
                      component={RemoteSigning}
                    />
                    <Route
                      path={routes.disclosureSigning.view(':token')}
                      exact
                      component={Disclosure}
                    />
                    <Route
                      path={routes.shortApplication.view(':agencyCode', ':branchCode?')}
                      exact
                      component={ShortApplication}
                    />
                    <Route
                      path={routes.shortApplication.referral(':agencyCode')}
                      exact
                      component={ShortApplication}
                    />
                    <Route path={routes.residentRequest.view} exact component={ResidentRequest} />
                    <Route component={LoginContainer} />
                  </Switch>
                ) : (
                  <Switch location={location}>
                    <Redirect exact from="/" to={routes.home.view} />
                    {/** login is mandatory for these routes */}
                    <Route path={routes.applications.view} exact component={Applications} />
                    <Route path={routes.groups.view} exact component={Groups} />
                    <Route path={routes.groups.edit} exact component={EditGroup} />
                    <Route path={routes.groups.application} exact component={EditGroup} />
                    <Route
                      path={routes.applicationPreferences.new}
                      exact
                      component={ApplicationScreen}
                    />

                    <Route
                      path={routes.tenantMaintenanceRequestForm.view}
                      exact
                      component={MaintenanceForm}
                    />
                    <Route path={routes.home.view} exact component={Dashboard} />
                    <Route
                      path={routes.submitProperties.new}
                      exact
                      component={SubmitPropertiesScreen}
                    />
                    <Route
                      path={routes.additionalDocuments.path}
                      exact
                      component={AdditionalDocuments}
                    />
                    <Route path={routes.myProfile.new} exact component={MyProfileFormsContainer} />
                    <Route
                      path={routes.myProfile.view(':formName')}
                      exact
                      component={MyProfileFormsContainer}
                    />
                    <Route
                      path={routes.applicationMyProfile.new}
                      exact
                      component={MyProfileFormsContainer}
                    />
                    <Route
                      path={routes.applicationMyProfile.view(':formName')}
                      exact
                      component={MyProfileFormsContainer}
                    />
                    <Route
                      path={routes.additionalApplicant.new(':index')}
                      exact
                      component={MyProfileFormsContainer}
                    />
                    <Route
                      path={routes.additionalApplicant.view(':index', ':formName')}
                      exact
                      component={MyProfileFormsContainer}
                    />
                    <Route
                      path={routes.viewProperty.view(':refId')}
                      exact
                      component={ViewPropertyScreen}
                    />
                    <Route
                      path={routes.remoteSigning.view(':token')}
                      exact
                      component={RemoteSigning}
                    />
                    <Route
                      path={routes.disclosureSigning.view(':token')}
                      exact
                      component={Disclosure}
                    />
                    <Route path={routes.success.new} exact component={ApplicationSent} />
                    <Route path={routes.error.new} exact component={ErrorPage} />
                    <Route
                      path={routes.properties.view(':agencyCode', ':branchCode?')}
                      exact
                      component={SelectPropertiesScreen}
                    />
                    <Route
                      path={routes.viewProperty.view(':refId')}
                      exact
                      component={ViewPropertyScreen}
                    />
                    <Route
                      path={routes.properties.generic}
                      exact
                      component={SelectPropertiesScreen}
                    />
                    <Route
                      path={routes.advancedSearch.view(':agencyCode', ':branchCode?')}
                      exact
                      component={AdvancedSearch}
                    />
                    <Route path={routes.advancedSearch.generic} exact component={AdvancedSearch} />
                    <>
                      {/** login is mandatory for these routes */}
                      <Route
                        path={routes.applicationPreferences.new}
                        exact
                        component={ApplicationScreen}
                      />
                      <Route
                        path={routes.submitProperties.new}
                        exact
                        component={SubmitPropertiesScreen}
                      />
                      <Route
                        path={routes.myProfile.new}
                        exact
                        component={MyProfileFormsContainer}
                      />
                      <Route
                        path={routes.myProfile.view(':formName')}
                        exact
                        component={MyProfileFormsContainer}
                      />
                      <Route
                        path={routes.additionalApplicant.new(':index')}
                        exact
                        component={MyProfileFormsContainer}
                      />
                      <Route
                        path={routes.additionalApplicant.view(':index', ':formName')}
                        exact
                        component={MyProfileFormsContainer}
                      />
                      <Route
                        path={routes.viewProperty.view(':refId')}
                        exact
                        component={ViewPropertyScreen}
                      />
                      {/* common for login & without login */}
                      <Route path={routes.login.view} exact component={LoginContainer} />
                      <Route
                        path={routes.welcomeReferee.view}
                        exact
                        component={WelcomeRefereeScreen}
                      />
                      <Route
                        path={routes.questionnaire.view}
                        exact
                        component={QuestionnaireScreen}
                      />
                      <Route path={routes.thankyouScreen.view} exact component={ThankyouScreen} />
                      <Route path={routes.referenceToolError.view} exact component={ErrorScreen} />
                      <Route
                        path={routes.registrationConfirmation.new}
                        exact
                        component={RegistrationConfirmationComponent}
                      />
                      <Route
                        path={routes.shortApplication.view(':agencyCode', ':branchCode?')}
                        exact
                        component={ShortApplication}
                      />

                      <Route
                        path={routes.shortApplication.referral(':agencyCode')}
                        exact
                        component={ShortApplication}
                      />
                      <Route path={routes.login.view} exact component={LoginContainer} />
                    </>
                    <Route component={ErrorPage} />
                  </Switch>
                )}
                {/* {!isReferenceScreen() && <SortedFooter />} */}
              </div>
            </div>
          </>
        )}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  flushData: () => {
    dispatch(SuccessActions.flushData());
  },
  refreshMasterProfile: () => {
    dispatch(LoginActions.refreshMasterProfile());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
